import React, { useEffect } from "react";
import Player from "./Player";

const Container = () => {
    // useEffect(() => {
    //     const message = JSON.stringify({
    //         type: "initialLoad",
    //         message: "HtAvyYZ9gd0",
    //     });
    //     window.postMessage(message, "*");
    // }, []);
    const handleClick = () => {
        const message = JSON.stringify({
            type: "initialLoad",
            message: "fullscreen",
        });
        // window.postMessage(message, "*");
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(message);
        }
    };
    const handleExitFullscreen = () => {
        const message = JSON.stringify({
            type: "initialLoad",
            message: "exitfullscreen",
        });
        // window.postMessage(message, "*");
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(message);
        }
    };
    return (
        <div>
            <Player />
            <button onClick={handleClick}>Fullscreen</button>
            <button onClick={handleExitFullscreen}>Exit Fullscreen</button>
        </div>
    );
};

export default Container;
