import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
function Player() {
    const playerRef = useRef(null);
    const [videoId, setVideoId] = useState("");
    const [searchParams] = useSearchParams();
    const videoIdParams = searchParams.get("videoid");
    useEffect(() => {
        setVideoId(videoIdParams);
    }, [videoIdParams]);

    const [message, setMessage] = useState("");
    // useEffect(() => {
    //     const handleMessage = (event) => {
    //         try {
    //             const messageData = JSON.parse(event.data);

    //             if (messageData.type === "initialLoad" && messageData.message) {
    //                 setMessage(messageData.message);
    //                 // setVideoId(messageData.message);
    //             }
    //         } catch (error) {}
    //     };

    //     window.addEventListener("message", handleMessage);

    //     return () => {
    //         window.removeEventListener("message", handleMessage);
    //     };
    // }, []);

    useEffect(() => {
        if (videoId !== "") {
            const loadYouTubeIframeAPI = () => {
                return new Promise((resolve) => {
                    if (window.YT) {
                        resolve();
                        return;
                    }
                    const script = document.createElement("script");
                    script.src = "https://www.youtube.com/iframe_api";
                    script.onload = resolve;
                    document.head.appendChild(script);
                });
            };

            const createYouTubePlayer = () => {
                if (!window.YT) return;

                // eslint-disable-next-line no-undef
                playerRef.current = new YT.Player(`player-${videoId}`, {
                    height: "100%",
                    width: "100%",
                    videoId: videoId,
                    playerVars: {
                        playsinline: 1,
                        controls: 0,
                        modestbranding: 1,
                        rel: 0,
                        showinfo: 0,
                        iv_load_policy: 3,
                        fs: 0,
                        disablekb: 1,
                        autoplay: 0,
                        // muted: 1,
                    },
                    events: {
                        onReady: onPlayerReady,
                        onStateChange: onPlayerStateChange,
                    },
                });
            };

            loadYouTubeIframeAPI().then(() => {
                if (window.YT && window.YT.Player) {
                    createYouTubePlayer();
                } else {
                    window.onYouTubeIframeAPIReady = createYouTubePlayer;
                }
            });

            function onPlayerReady(event) {}

            function onPlayerStateChange(event) {}
        }
    }, [videoId]);

    return (
        <>
            <div
                className="container-p"
                style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "56.25%",
                }}
            >
                <div id={`player-${videoId}`} />
            </div>
            <div>{message}</div>
        </>
    );
}

export default Player;
