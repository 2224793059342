import React, { useEffect, useState } from "react";

const EmbedableIframe = () => {
    const [message, setMessage] = useState("");

    useEffect(() => {
        const handleReceiveMessage = (event) => {
            console.log("Received message:", event.data);
            setMessage(event.data);
        };

        window.addEventListener("message", handleReceiveMessage);

        return () => {
            window.removeEventListener("message", handleReceiveMessage);
        };
    }, []);

    return (
        <div style={{ padding: "20px", border: "1px solid #ccc" }}>
            <h2>Iframe Content</h2>
            <p>
                Message from Parent:{" "}
                {typeof message === "object"
                    ? JSON.stringify(message)
                    : message}
            </p>
        </div>
    );
};

export default EmbedableIframe;
